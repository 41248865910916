import { FormControl, Grid, InputBase, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import { SemiBold, countryCode, getMobileLimitBasedOnCC } from "../../utils";
import { SimpleSelect } from "./select";

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",

  },

  input: {
    position: "relative",
    width: "100%",
    marginLeft: "4px",
    fontFamily: SemiBold,
    fontSize:(props) => (props.fontSize ?? "0.875rem !important"),
    "& input::placeholder": {
      fontSize:(props) => (props.fontSize ?? "0.875rem !important"),
      fontFamily:SemiBold,
      color:"#a2a2a2 !important",
      opacity:"1 !important",
    },
  },
  
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginBottom: 0,
  },
  wrapper: {
    border: `1px solid #E4E8EE`,
    borderRadius: 4,
    height: 48,
    alignItems: "center",
    background: (props) => props?.textBoxBgColor ?? "auto"
  },
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize:(props) => (props.fontSize ?? "0.75rem !important"),
    fontFamily: SemiBold
  },

}));

export const MobileNumberInputComponent = (props) => {
  const { setIsError = () => false } = props;
  const classes = useStyles(props);

  const [selectValue, setSelectValue] = React.useState({
    mobile: props?.value?.mobile ?? "",
    mobile_code: props?.value?.mobile_code ?? "+91",
  });

  const [limits, setLimits] = React.useState(10);
  const [isvalid, setIsvalid] = React.useState(true);

  const mobileNoValidation = (limitValue = limits) => {
    let inputLengthValue = selectValue?.mobile?.length;

    if (limitValue) {
      if (limitValue === inputLengthValue) {
        setIsvalid(true);
      } else if (limitValue > inputLengthValue) {
        setIsvalid(false);
      } else {
        setIsvalid(true);
      }
    }
  };

  const handleChangeSelect = (mobile_code = {}) => {
    let newState = {
      mobile: selectValue.mobile,
      mobile_code,
    };

    setSelectValue(newState);
    props?.handleChange(newState);

    if (Object.keys(mobile_code)?.length > 0) {
      const limit = getMobileLimitBasedOnCC(mobile_code);
      setLimits(limit);
    }
  };

  const handleChange = (mobile) => {
    if (mobile?.length <= limits) {
      let newState = {
        mobile,
        mobile_code: selectValue.mobile_code,
      };

      setSelectValue(newState);
      props?.handleChange(newState);
    }
  };

  React.useEffect(() => {
    const limit = getMobileLimitBasedOnCC(props?.value?.mobile_code);
    mobileNoValidation(limit);

    // eslint-disable-next-line
  }, [selectValue]);

  React.useEffect(() => {
    if (isvalid === false &&
      limits !== null &&
      selectValue?.mobile?.length > 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isvalid, limits, selectValue])

  React.useEffect(() => {
    if (props?.value) {
      setSelectValue({
        ...selectValue,
        mobile: props?.value?.mobile ?? "",
        mobile_code: props?.value?.mobile_code ?? "+91",
      });
    }
    // eslint-disable-next-line
  }, [props?.value]);

  React.useEffect(() => {
    if (props?.onErrorOccured) {
      props?.onErrorOccured(!isvalid);
    }

    // eslint-disable-next-line
  }, [isvalid]);

  return (
    <div className={classes.root} noValidate>
      {/* Input Label */}
      <Typography className={classes.Label}>
        {props?.label}{" "}
        {props?.isRequired && (
          <Typography variant="caption" color="error">
            *
          </Typography>
        )}
      </Typography>

      <FormControl>
        <div>
          <Grid container className={classes.wrapper}>
            {/* Mobile No Country Code Select */}
            <Grid item >
              <div style={{ borderRight: "1px solid #c1c1c1" }}>
                <SimpleSelect
                  className={classes.select}
                  options={countryCode}
                  handleChangeSelect={handleChangeSelect}
                  value={selectValue?.mobile_code ?? ""}
                  disabled={props.isReadonly || props.disabled}
                  errorValidation={props?.errorValidation}
                />
              </div>
            </Grid>

            {/* Mobile Number Input Field */}
            <Grid item >
              <BootstrapInput
               sx={{fontSize:props?.fontSize??"0.75rem"}}
                inputProps={{ maxLength: limits && limits }}
                type={"number"}
                autoFocus={props.autoFocus ? true : false}
                disabled={(props?.isReadonly || props?.disabled) ?? false}
                onInvalid={(e) => {
                  e.preventDefault();
                }}
                placeholder={props.placeholder}
                onChange={(e) => handleChange(e.target.value)}
                value={selectValue?.mobile}
                error={
                  props?.errorValidation && props?.errorValidation?.error
                    ? props?.errorValidation?.error
                    : false
                }
                onKeyPress={(e) => props.enterKeyPress ? props.enterKeyPress(e) : ""}
              />
            </Grid>
          </Grid>
        </div>

        {/* Warning Message */}
        <Grid container direction={"row"}>
          {isvalid === false &&
            limits !== null &&
            selectValue?.mobile?.length > 0 && (
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="caption" color="error">
                  {"Please enter " + limits + " digits"}
                </Typography>
              </Grid>
            )}

          {/* Error Message */}
          {props?.errorValidation?.error && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="caption" color="error">
                {props?.errorValidation?.errorMessage ?? ""}
              </Typography>
            </Grid>
          )}

          {/* Field required Message */}
          {props.isError && <Typography variant={"caption"} color={"error"}>{props.errorMessage}</Typography>}

          {props.error && (
            <Typography variant="caption" color="error">
              {props.helperText}
            </Typography>
          )}
        </Grid>
      </FormControl>
    </div>
  );
};



// Specifies the required props for the component:
MobileNumberInputComponent.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isReadonly: PropTypes.bool,
  // value: PropTypes.object,
  type: PropTypes.string,
  errorValidation: PropTypes.object,
  handleChange: PropTypes.func,
  onErrorOccured: PropTypes.func,
};
MobileNumberInputComponent.defaultProps = {
  handleChange: () => { }
}