import { NetworkCall } from "../networkcall";
import { LocalStorageKeys, NetWorkCallMethods } from "./constants";

export const getCurrentBuildNumber = async () => {
    let version = "";
    let endpoint = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""
        }/meta.json`;

    await NetworkCall(endpoint, NetWorkCallMethods.get, null, null, false, true)
        .then((_) => {
            version = _.data.version;
            localStorage.setItem(LocalStorageKeys?.version, version);
        })
        .catch((err) => {
            console.log("err:", err);
        });

    return version;
};